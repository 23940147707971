<template>
  <b-navbar
    :toggleable="toggleable"
    type="app"
    :class="{
      'has-action-item': hasActionItem,
      impersonated: isImpersonatedPractician,
    }"
  >
    <div>
      <b-navbar-toggle v-if="me" target="navbar-collapse"></b-navbar-toggle>

      <b-navbar-brand :to="{ name: 'index' }">
        <Logo class="logo" />
        <LogoSmall class="logo--small" />
      </b-navbar-brand>
    </div>

    <div class="navbar-app__action-group d-flex flex-row">
      <slot />
    </div>

    <b-collapse is-nav>
      <b-navbar-nav v-if="displayMenu" class="navbar-desktop">
        <b-nav-item
          v-for="(link, group) in links"
          :key="link.label"
          :to="link.route ? { name: link.route } : ''"
          :href="link.url ? link.url : null"
          :target="link.outside ? '_blank' : '_self'"
          @click="clickOnSponsorLink(group)"
        >
          <span class="d-none d-xl-inline">{{ $t(link.label) }}</span>
          <span class="d-xl-none">{{ $t(link.shortLabel) }}</span>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto  navbar-desktop">
        <b-nav-item-dropdown
          v-if="me && displayUserName"
          :text="me.fullName"
          class="account-nav-item"
          right
        >
          <b-dropdown-item
            v-for="link in userDropdownLinks"
            :key="link.label"
            :to="link.route ? { name: link.route } : null"
            :href="link.url ? link.url : null"
          >
            {{ $t(link.label) }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item
          v-if="me && displayUserName"
          class="help-nav-item"
          href="https://aide.medoucine.com"
          target="_blank"
        >
          <HelpIcon class="help-icon" width="22px" height="22px" />
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>

    <a v-if="isImpersonatedPractician" class="admin-link" href="/logout-react">
      <AdminIcon class="admin-icon" width="31px" height="27px" />
      <span>ADMIN</span>
    </a>

    <HamburgerMenu
      v-if="me"
      id="navbar-collapse"
      :links="displayMenu ? links : {}"
      :user-links="userDropdownLinks"
      :username="me.fullName"
    />
  </b-navbar>
</template>
<script>
import Vue from 'vue'
import { NavbarPlugin } from 'bootstrap-vue'
import jwtDecode from 'jwt-decode'
import AdminIcon from '@/assets/icons/go-admin.svg'
import HelpIcon from '@/assets/icons/help.svg'
import CalendarIcon from '@/assets/icons/calendar.svg'
import PageIcon from '@/assets/icons/presentation.svg'
import CommunityIcon from '@/assets/icons/noun-community.svg'
import Logo from '@/assets/icons/logo-white.svg'
import LogoSmall from '@/assets/icons/logo-white-small.svg'
import { getNavbarInfo } from '@/graphql/user.gql'
import HamburgerMenu from '@/components/navbar/hamburger-menu'
import trackEvent from '@/services/track-event'

Vue.use(NavbarPlugin)

const LINKS = {
  default: {},
  user: {
    reservations: {
      label: 'Navbar.link.myAppointments',
      shortLabel: 'Navbar.link.myAppointments:short',
      route: 'agenda',
      icon: CalendarIcon,
    },
    customers: {
      label: 'Navbar.link.customers',
      shortLabel: 'Navbar.link.customers:short',
      // route: 'customers',
      icon: CommunityIcon,
      url: '/customers',
      outside: false,
    },
    session: {
      label: 'Navbar.link.myMedoucinePage',
      shortLabel: 'Navbar.link.myMedoucinePage:short',
      route: 'pro-profile',
      icon: PageIcon,
    },
    performance: {
      label: 'Navbar.link.performance',
      shortLabel: 'Navbar.link.performance:short',
      // route: 'community',
      url: '/community',
      outside: false,
    },
    community: {
      label: 'Navbar.link.community',
      shortLabel: 'Navbar.link.community:short',
      url: 'https://communaute-pro.medoucine.com',
      outside: true,
      icon: CommunityIcon,
    },
    sponsorship: {
      label: 'Navbar.link.sponsorship',
      shortLabel: 'Navbar.link.sponsorship:short',
      url: 'https://lp.therapeute-medecine-douce.fr/parrainer-un-praticien-cs',
      outside: true,
    },
  },
}

const USER_DROPDOWN_LINKS = {
  account: {
    label: 'Navbar.link.account',
    route: 'account',
  },
  informations: {
    label: 'Navbar.link.informations',
    route: 'account-administrative',
  },
  contract: {
    label: 'Navbar.link.contract',
    route: 'account-contract',
  },
  mobile: {
    label: 'Navbar.link.mobile',
    url: 'https://aide.medoucine.com/application-medoucine-sur-mon-telephone',
  },
  logout: {
    label: 'Navbar.link.logout',
    url: '/logout-react',
  },
}

export default {
  components: { HelpIcon, Logo, LogoSmall, HamburgerMenu, AdminIcon },
  props: {
    toggleable: {
      type: String,
      default: 'lg',
    },
  },
  data: () => ({
    me: null,
  }),
  apollo: {
    me: {
      query: getNavbarInfo,
    },
  },
  computed: {
    links() {
      return LINKS.user
    },
    userDropdownLinks() {
      if (this.displayMenu === false) {
        return { logout: USER_DROPDOWN_LINKS.logout }
      }

      // Remove mobile app promotion when on it.
      // No need to promote something when it is already done.
      if (this.isOnApplication()) {
        delete USER_DROPDOWN_LINKS.mobile
      }

      return USER_DROPDOWN_LINKS
    },
    displayUserName() {
      return !String(this.$route.name).includes('login')
    },
    displayMenu() {
      return (
        this.me &&
        String(this.$route.name).includes('signature') === false &&
        String(this.$route.name).includes('login') === false &&
        String(this.$route.name).includes('onboarding') === false
      )
    },
    hasActionItem() {
      return !!this.$slots.default
    },
    isImpersonatedPractician() {
      const token = this.$apolloHelpers && this.$apolloHelpers.getToken()
      if (token) {
        const payload = jwtDecode(token)
        return payload && payload.aid !== null
      } else {
        return false
      }
    },
  },
  methods: {
    isOnApplication() {
      let isOnApplication = false

      if (navigator && navigator.standalone) {
        isOnApplication = true
      }

      if (typeof window.matchMedia === 'function') {
        if (window.matchMedia('(display-mode: standalone)').matches) {
          isOnApplication = true
        }
      }

      return isOnApplication
    },
    clickOnSponsorLink(group) {
      if (group === 'sponsorship') {
        trackEvent('sponsor.url.click ', {
          event_group: 'pro_outside_link',
          origin: 'nuxt',
        })
      }
    },
  },
}
</script>
<style lang="scss">
.navbar-app {
  position: sticky;
  top: 0;
  z-index: 9;
  margin-bottom: 20px;
  color: $navbar-dark-color;
  background-color: $navbar-dark-color-bg;

  &.impersonated {
    background-color: $red;
  }

  .admin-link {
    display: flex;
    flex-direction: column;
    padding-left: 14px;
    margin-left: 14px;
    border-left: 1px $white solid;

    &:hover {
      text-decoration: none;
    }

    span {
      font-size: 12px;
      font-weight: bold;
      color: $white;
    }
  }

  @include media-breakpoint-down(sm) {
    font-size: rem(16px);
  }

  &__action-group {
    > * {
      display: inline-block;
      padding: 0 $navbar-nav-link-padding-x;
      margin-left: rem(8px);
      font-size: $navbar-font-size;
      color: $navbar-dark-color;
      text-align: right;
      cursor: pointer;

      @include hover {
        color: $navbar-dark-hover-color;
      }

      @include caret-bg(
        $navbar-nav-link-padding-x,
        $line-height-lg,
        $navbar-dark-color,
        $navbar-dark-hover-color
      );
    }

    @include media-breakpoint-up($navbar-action-group-breakpoint-up) {
      display: none !important;
    }
  }

  .help-icon,
  .admin-icon {
    path {
      fill: #fff;
    }
  }

  .navbar-brand {
    margin-right: rem(20px);
    color: $navbar-dark-color;

    @include media-breakpoint-down(sm) {
      padding: 0; // extend boostrap
      margin-right: 0;
    }
    // switch logo when size is < md
    .logo {
      width: auto;
      height: 44.4px;

      @include media-breakpoint-down(md) {
        display: none;
      }

      &--small {
        display: none;
        height: 28px;
        margin-right: 0;
        margin-left: 15px;

        @include media-breakpoint-down(md) {
          display: inline;
        }
      }
    }
  }

  &.has-action-item {
    // switch logo when size is < lg
    .navbar-brand {
      .logo--small {
        // remove logo on really small devices
        @media (max-width: 350px) {
          display: none !important;
        }

        @include media-breakpoint-down(lg) {
          display: inline;
        }
      }
      .logo {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
  }

  .navbar-nav.navbar-desktop {
    .dropdown-menu {
      @include media-breakpoint-up(sm) {
        // top arrow
        &::before {
          position: absolute;
          top: -8px;
          right: 80px;
          height: 0;
          content: '';
          border-right: 25px solid transparent;
          border-bottom: 20px solid $white;
          border-left: 25px solid transparent;
        }
      }
    }

    .nav-link {
      margin: 0 0.66rem;
      color: $navbar-dark-color;

      @media (min-width: 1280px) {
        margin: 0 rem(22px);
      }

      @include hover-focus {
        color: $navbar-dark-hover-color;
      }

      &.nuxt-link-active {
        font-weight: bold;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .help-nav-item .nav-link {
      @include media-breakpoint-up(sm) {
        margin: 0;
      }
    }
    .account-nav-item .nav-link {
      @include media-breakpoint-up(sm) {
        margin-right: 0;
        outline: 0;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-toggler {
    padding: 0;
    color: $navbar-dark-color;
    border: 0;
    border-color: $navbar-dark-toggler-border-color;
    outline: 0 !important;
  }

  .navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }

  .dropdown-item {
    color: $navy;
  }
}
</style>
