<template>
  <div class="customer-select">
    <LoupeIcon class="customer-select-icon" />

    <Multiselect
      id="customer"
      v-model="innerValue"
      name="customer"
      :options="customerOptions"
      v-bind="MultiselectConf"
      :show-labels="false"
      :show-pointer="false"
      :preserve-search="true"
      :option-height="45"
      :loading="customerSelectIsSearching"
      :placeholder="$t('placeholder.customer:select')"
      @search-change="searchChangeCustomer"
    >
      <template slot="singleLabel" slot-scope="props">{{
        props.option.text
      }}</template>
      <template slot="option" slot-scope="props">
        <div class="option">
          <div class="option__title">
            <span v-if="props.option.familyName" class="text-uppercase">{{
              props.option.familyName
            }}</span>
            <span v-if="props.option.givenName" class="text-capitalize">{{
              props.option.givenName
            }}</span>
          </div>
          <div class="option__phone">{{ props.option.telephone }}</div>
          <div v-if="props.option.email" class="option__email">
            • {{ props.option.email }}</div
          >
        </div>
      </template>

      <template slot="afterList">
        <div class="after-list" @click="createNewClient">
          <AddIcon class="after-list__icon" />
          {{ $t('CustomerSelect.createCustomer') }}
        </div>
      </template>

      <template #noOptions>{{ $t('noOptions') }}</template>
      <template #noResult>{{ $t('noResult') }}</template>
    </Multiselect>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import Multiselect from 'vue-multiselect'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import AddIcon from '@/assets/icons/add.svg'
import LoupeIcon from '@/assets/icons/loupe.svg'
import trackEvent from '@/services/track-event'

const MultiselectConf = {
  label: 'name',
  trackBy: 'id',
  searchable: true,
  internalSearch: false,
}

export default {
  components: {
    Multiselect,
    AddIcon,
    LoupeIcon,
  },
  data() {
    return {
      innerValue: null,
      selectValue: null,
      customerSelectIsSearching: false,
      customerOptions: [],
      MultiselectConf,
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal)
      this.$emit('change', newVal) // to keep the actual @change Handler
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    },
    // Handles multiselect model changes.
    selectValue(newVal) {
      if (newVal && this.targetedProps) {
        this.innerValue = newVal[this.targetedProps]
        if (!this.innerValue) {
          this.innerValue = newVal
        }
      } else {
        this.innerValue = newVal
      }
    },
  },
  methods: {
    createNewClient() {
      this.$emit('customer:create')
    },
    formatResult($customers) {
      const $customersFormatted = []
      for (const $customer of $customers) {
        const { uuid, firstname, lastname, phone, email, contactId } = $customer
        $customersFormatted.push({
          id: uuid,
          originalId: contactId,
          text: `${firstname} ${lastname}`,
          familyName: lastname,
          givenName: firstname,
          telephone: phone,
          email,
        })
      }
      return $customersFormatted
    },
    searchChangeCustomer: debounce(async function(name) {
      if (!String(name).trim()) {
        return
      }

      trackEvent('customer.search', {
        event_value: name,
      })

      this.customerSelectIsSearching = true
      try {
        const token = this.$apolloHelpers && this.$apolloHelpers.getToken()
        if (token) {
          const payload = jwtDecode(token)
          const url = `${process.env.APP_CLIENT_BASE_URL}/api-pro/customer/list/practician/${payload.pid}`
          const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
            params: { search: name },
          })

          const customersResponse = response.data.data.customers
          this.customerOptions = this.formatResult(customersResponse)
        }
      } catch (error) {
        console.error('Failed to fetch customers:', error)
      } finally {
        this.customerSelectIsSearching = false
      }
    }, 400),
  },
}
</script>
<style lang="scss">
.customer-select {
  &-icon {
    position: absolute;
    left: 30px;
    z-index: 60;
    height: 30px;
    margin-top: 15px;
    margin-right: 10px;
  }
  .multiselect {
    &__tags {
      height: 55px;
      padding-left: 50px;
    }
    &__input {
      height: 30px;
      padding-left: 15px;
    }
    &__placeholder {
      font-size: 18px;
      color: $gray-light;
    }
    &__select {
      display: none;
    }
    &__content-wrapper {
      border-radius: 5px;
      box-shadow: 0 0 1px 0 rgba(50, 50, 50, 0.2),
        0 2px 4px 2px rgba(50, 50, 50, 0.1);
    }
    &__option {
      .option {
        display: flex;

        &__title {
          margin-right: 15px;
          font-size: 18px;
        }
        &__phone,
        &__email {
          color: #7b898f;
        }
        &__phone {
          margin-right: 5px;
        }
      }
    }

    .after-list {
      position: sticky;
      bottom: 0;
      padding: 15px;
      font-size: 18px;
      color: $navy;
      cursor: pointer;
      background-color: #f5f6f7;

      &__icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
}
</style>
