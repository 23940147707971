<template>
  <div>
    <a
      v-if="locationHref"
      :href="route"
      class="m-submenuApp__item toto"
      @click.prevent="gotHref(route)"
    >
      <component :is="icon" v-if="icon" />
      <span class="menu-item__label">{{ $t(label) }}</span>
    </a>

    <template v-else>
      <a
        v-if="isHref"
        :href="route"
        :target="outside ? '_blank' : '_self'"
        class="m-submenuApp__item"
      >
        <component :is="icon" v-if="icon" />
        <span class="menu-item__label">{{ $t(label) }}</span>
      </a>

      <nuxt-link v-else :to="route" class="m-submenuApp__item">
        <component :is="icon" v-if="icon" />
        <span class="menu-item__label">{{ $t(label) }}</span>
      </nuxt-link>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    route: {
      type: String,
      required: true,
    },
    isHref: {
      type: Boolean,
      default: false,
    },
    outside: {
      type: Boolean,
      default: false,
    },
    locationHref: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    gotHref(url) {
      window.location.href = url
    },
  },
}
</script>
