<template>
  <div class="m-menuApp" :class="toggleable">
    <input
      id="menuApp"
      class="a-inputMenu"
      type="checkbox"
      @change="handleMenuToggle"
    />
    <label class="a-hamburger" for="menuApp"></label>
    <label class="a-overlayMenuApp" for="menuApp"></label>
    <div class="a-logo">
      <component :is="currentLogo || defaultLogo" />
    </div>
    <div class="m-agendaSubMenu">
      <slot />
    </div>
    <div class="m-submenuApp">
      <div>
        <PracticianInfo
          v-if="me"
          :first-name="me.firstName"
          :last-name="me.lastName"
          :picture="me.Business.Profile.ProfilePicture.uri"
        />
        <MenuItem
          v-for="link in links"
          :key="link.key"
          :label="link.label"
          :route="link.route"
          :icon="link.icon"
          :location-href="link.locationHref || false"
          :class="{ '-active': activeItem === link.key }"
          @click.native="setActiveItem(link.key)"
        />
      </div>
      <MenuItem
        :is-href="true"
        :label="logout.label"
        :route="logout.route"
        :icon="logout.icon"
        :class="{ '-active': activeItem === 'logout' }"
        @click.native="setActiveItem('logout')"
      />
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/images/logo-pro.svg'
import PracticianInfo from '@/components/app-pro/practician-block'
import MenuItem from '@/components/app-pro/menu-item'
import DashboardIcon from '@/assets/icons/dashboard.svg'
import AgendaIcon from '@/assets/icons/calendar-event.svg'
import PlaceIcon from '@/assets/icons/pin-drop.svg'
import MotiveIcon from '@/assets/icons/leaf.svg'
import CustomerIcon from '@/assets/icons/user.svg'
import ReviewIcon from '@/assets/icons/star.svg'
import LogoutIcon from '@/assets/icons/log-out.svg'
import { me } from '@/graphql/auth.gql'

const LINKS = {
  itemMenu: [
    {
      label: 'Navbar.link.home',
      route: '/',
      icon: DashboardIcon,
      key: 'dashboard',
    },
    {
      label: 'Navbar.link.myAppointments',
      route: '/agenda',
      icon: AgendaIcon,
      key: 'agenda',
    },
    {
      label: 'Navbar.link.workEnvironment',
      route: '/agenda/settings/work-environment',
      icon: PlaceIcon,
      key: 'workEnvironment',
    },
    {
      label: 'Navbar.link.motive',
      route: '/agenda/settings/appointment-formula',
      icon: MotiveIcon,
      key: 'motive',
    },
    {
      label: 'Navbar.link.customers',
      route: '/customers',
      icon: CustomerIcon,
      key: 'customers',
      locationHref: true,
    },
    {
      label: 'Navbar.link.reviews',
      route: '/pro-profile/reviews',
      icon: ReviewIcon,
      key: 'reviews',
    },
  ],
}

const LOGOUT = {
  logout: {
    label: 'Navbar.link.logout',
    route: '/logout-react',
    icon: LogoutIcon,
  },
}

export default {
  components: {
    Logo,
    PracticianInfo,
    MenuItem,
  },

  props: {
    toggleable: {
      type: String,
      default: ' ',
    },
    currentLogo: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    me: null,
    photo: null,
    loading: true,
    activeItem: null,
    defaultLogo: Logo,
  }),

  apollo: {
    me: {
      query: me,
    },
  },

  computed: {
    links() {
      return LINKS.itemMenu
    },

    logout() {
      return LOGOUT.logout
    },
  },

  created() {
    this.setActiveItemBasedOnRoute(this.$route.path)

    this.closeMenu()

    this.$nextTick(() => {
      const firstMenuItem = this.$el.querySelector('.m-submenuApp .menu-item')
      if (firstMenuItem) {
        firstMenuItem.click()
      }
    })
  },

  mounted() {
    window.addEventListener('touchstart', this.handleTouchStart)
    window.addEventListener('touchmove', this.handleTouchMove)
    window.addEventListener('touchend', this.handleTouchEnd)
    this.$watch('$route', to => {
      this.setActiveItemBasedOnRoute(to.path)
    })
  },

  beforeDestroy() {
    window.removeEventListener('touchstart', this.handleTouchStart)
    window.removeEventListener('touchmove', this.handleTouchMove)
    window.removeEventListener('touchend', this.handleTouchEnd)
  },

  methods: {
    setActiveItem(key) {
      this.activeItem = key
      this.closeMenu()
    },

    setActiveItemBasedOnRoute(route) {
      const matchingLink = Object.values(LINKS.itemMenu).find(
        link => link.route === route
      )
      if (matchingLink) {
        this.activeItem = matchingLink.key
      } else if (route === 'logout') {
        this.activeItem = 'logout'
      } else {
        this.activeItem = null
      }
    },

    handleMenuToggle(event) {
      if (event.target.checked) {
        document.body.classList.add('menu-active')
      } else {
        document.body.classList.remove('menu-active')
      }
    },
    handleTouchStart(event) {
      const touch = event.touches[0]
      this.startX = touch.clientX
      this.startY = touch.clientY
    },
    handleTouchMove(event) {
      // Empêcher le comportement par défaut lors du déplacement
      event.preventDefault()
    },
    handleTouchEnd(event) {
      const touch = event.changedTouches[0]
      const deltaX = touch.clientX - this.startX
      const deltaY = touch.clientY - this.startY

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        // Swipe horizontal
        if (deltaX < -50) {
          // Swipe gauche
          this.closeMenu()
        }
      }
    },

    closeMenu() {
      const menuCheckbox = document.querySelector('#menuApp')
      if (menuCheckbox && menuCheckbox.checked) {
        menuCheckbox.checked = false
        document.body.classList.remove('menu-active')
      }
    },
  },
}
</script>
